/* You can add global styles to this file, and also import other style files */
@import "./theme.scss";
// regular style toast 
@import 'ngx-toastr/toastr';

/*importing material icons*/
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
html,
body {
    height: 100%;
    margin: 0;
    font-family: "universal";
    color: #f2f2f2;
}

.mat-mdc-menu-panel {
    margin-top: 10px;
}

button:focus {
    outline: 0;
}

.custom-dialog>mat-dialog-container {
    padding: 0px !important;
    margin: 0px !important;
}

@font-face {
    font-family: universal;
    src: url(./assets/fonts/UniversLTStd-Light.otf) format("opentype");
}

@media screen and (max-width: 1600px) and (min-width:1000px) {
    .loginForm {
        height: 50% !important;
    }
}

@media screen and (max-width: 700px) {
    .loginForm {
        padding: 20px 20px 20px 20px;
    }
}

.fileDropZone {
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed rgb(36, 78, 95);
    border-radius: 5px;
    line-height: 2.5em;
    height: 96%;
    overflow: auto;
}

.fileDropContent {
    padding: 5px;
    height: 97%;
    min-height: 200px;
    color: black;
}

.mira-alternate-button {
    color: white;
    background: #001f40;
    font-weight: bold;
    border-radius: 5px;
    border-style: solid;
    border-color: #37aeb3;
    background-color:rgba(0,123,255,0.2);
    white-space: nowrap;
    overflow: visible;
    line-height: 36px;
    min-width: 64px;
    text-decoration: none;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size:1.3em;
    font-weight: 500;
    letter-spacing:1px; 
    cursor: pointer;    
}

.mira-alternate-button[disabled] {
    cursor: default;
    color: black;
    background:  #7A7A7A;
    border-color: #424242;
}

.mat-tab-body-wrapper {
    height: 100%;
    padding-top: 10px;
}

.mat-mdc-tooltip {
    margin-top: 5px !important;
}

.float {
    position: absolute !important;
    bottom: 2.5em !important;
    right: 4em !important;
}

.companyid-panel .mat-mdc-dialog-container {
    border-style: solid;
    border-color: #007bff;
}

.container {
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */
    display: flex;
    background-color: #000f19;
}

.modal-content {
    background-color: #000f19;
}

.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px;
    justify-content: space-between;
}

.toolbar-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.userButton {
    position: relative;
    cursor: pointer !important;;
    color: white !important;
    font-size: 28px;
    padding: 10px;
}

.modal-toolbar-row {
    display: grid !important;
    align-items: center;
    grid-template-columns: 40px 1fr 40px;
    width: 100%;
}

.loginCard{
    border-radius: 15px;
    padding: 40px 40px 40px 40px;
    border-color: #007bff;
    border-style: solid;
    border-width: 1px;
}

.mira.mat-mdc-card {
    border-radius: 15px;
    padding: 30px 30px 30px 30px;
    border-color: #007bff;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    background-color: #424242;
}

.mira.mat-mdc-card-header {
    display: flex;
    height: 30px;
}

.mira.mat-toolbar {
    background-color: #007bff;
}

.modal-container {
    background-color: #303030;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.mira.mat-mdc-form-field {
    width: 90%;
    margin: 8px;
}

/* width */
::-webkit-scrollbar {
    width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(47, 53, 58, 1);
    height: 90%;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(47, 53, 58, 1);
    border-radius: 5px;
}

.graphing-container .mat-tab-label {
    color:blue;
}
